import React, { useState, useEffect, useContext } from "react";
import { withStyles } from '@material-ui/core/styles';
import configData from '../../../config/config.json';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Button } from "@material-ui/core";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
      backgroundColor:'#eee'
    },
}))(MuiDialogContent);
  
const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const API_ENDPOINT = configData.API_ENDPOINT;

export default function SimpleDialog(props) {
    
    const { onClose, flights, packageUid, originalFlightInventoryUID, open, packagePricePP} = props;

    const handleClose = () => {
        onClose();
    };

    const changeFlight = async (e, flightInventoryUID) => {
        // Use the flightInventoryID to make an axios call to:
        console.log(`loadFlights`)
        let url = `${API_ENDPOINT}/search/packages/${packageUid}/flight/${flightInventoryUID}`

        await axios.get(
            url,
            {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true  
            }
          ).then(res=>{

            if (!res.data || res.data=='')  {
                console.error('DID NOT FIND FLIGHT INVENTORY UID')
                return
            }
            console.log(res)
            let redirect_url = window.location.href;  
            console.log(`old url: ${redirect_url}`)
            redirect_url = redirect_url.replace(packageUid, res.data)
            console.log(`new url: ${redirect_url}`)

            window.location.href = redirect_url;
          }).catch(err=>{
            console.log(err)
            alert('שגיאה')
          });

          //alert(resPackage.data)

    }

    // Search the flights array for a record whose flight?.raw_flight?.inventory_uid == originalFlightInventoryUID and place it as the first item in the array
    const flight = flights.find(flight => flight?.raw_flight?.inventory_uid == originalFlightInventoryUID)
    // add flight as the first item in the flights array (and remove it's other occurance)
    let flightsArray =  flights.filter(flight => flight?.raw_flight?.inventory_uid != originalFlightInventoryUID);
    if (flight)
        flightsArray.unshift(flight);

    return (
            <Dialog fullScreen aria-labelledby="simple-dialog-title" open={open} scroll="paper" id="change_flight" onClose={handleClose}>
                <AppBar style={{ position: 'relative', textAlign:'center'}}>
                        <Toolbar style={{background:'#124b71'}} tabIndex={0}>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h5" style={{flex: 1}}>
                                החלפת טיסה
                            </Typography>
                            <Box component="span" m={2}></Box>
                        </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className='ugly modalBody'  >
        
                        <Grid container>
                            <Grid item className='text-center' xs={12}>
                                {flightsArray.map((flight,index) => {
                                
                                    let outbound_arrival_time = flight?.raw_flight?.legs[0].arrival.split('T')[1].split('.')[0];
                                    outbound_arrival_time = outbound_arrival_time.split(':')[0]+':'+outbound_arrival_time.split(':')[1];
                                    let outbound_departure_time = flight?.raw_flight?.legs[0].departure.split('T')[1].split('.')[0];
                                    outbound_departure_time = outbound_departure_time.split(':')[0]+':'+outbound_departure_time.split(':')[1];
                                    let inbound_arrival_time = flight?.raw_flight?.legs[1].arrival.split('T')[1].split('.')[0];
                                    inbound_arrival_time = inbound_arrival_time.split(':')[0]+':'+inbound_arrival_time.split(':')[1];
                                    let inbound_departure_time = flight?.raw_flight?.legs[1].departure.split('T')[1].split('.')[0];
                                    inbound_departure_time = inbound_departure_time.split(':')[0]+':'+inbound_departure_time.split(':')[1];
                                    
                                    let outbound_departure_date = flight?.raw_flight?.legs[0].departure.split('T')[0];
                                    // convert to to DD/MM/YYYY format
                                    outbound_departure_date = outbound_departure_date.split('-')[2]+'/'+outbound_departure_date.split('-')[1]+'/'+outbound_departure_date.split('-')[0];

                                    let outbound_arrival_date = flight?.raw_flight?.legs[0].arrival.split('T')[0];
                                    // convert to to DD/MM/YYYY format
                                    outbound_arrival_date = outbound_arrival_date.split('-')[2]+'/'+outbound_arrival_date.split('-')[1]+'/'+outbound_arrival_date.split('-')[0];

                                    let inbound_departure_date = flight?.raw_flight?.legs[1].departure.split('T')[0];
                                    // convert to to DD/MM/YYYY format
                                    inbound_departure_date = inbound_departure_date.split('-')[2]+'/'+inbound_departure_date.split('-')[1]+'/'+inbound_departure_date.split('-')[0];    

                                    let inbound_arrival_date = flight?.raw_flight?.legs[1].arrival.split('T')[0];
                                    // convert to to DD/MM/YYYY format
                                    inbound_arrival_date = inbound_arrival_date.split('-')[2]+'/'+inbound_arrival_date.split('-')[1]+'/'+inbound_arrival_date.split('-')[0];

                                    let outbound_departure_airport_name = '';
                                    let outbound_arrival_airport_name = '';
                                    let inbound_departure_airport_name = '';
                                    let inbound_arrival_airport_name = '';
                                    let priceDeltaPP = flight?.price_pp-packagePricePP;
                                    if (priceDeltaPP > 0)
                                        priceDeltaPP = Math.ceil(priceDeltaPP)
                                    else
                                        priceDeltaPP = Math.floor(priceDeltaPP)
                                    let flightInventoryUID = flight?.raw_flight?.inventory_uid;
                                    let outbound_duration = flight?.raw_flight?.legs[0].duration;
                                    let inbound_duration = flight?.raw_flight?.legs[1].duration;

                                    let goTime = parseInt(outbound_duration) / 60 / 60;
                                    let goSec = goTime - Math.floor(goTime);
                                    goSec = Math.round(goSec * 60);
                                    goTime = `${Math.floor(goTime)} שעות ו-${goSec} דקות`;
                                
                                    //Get Return Time and Seconds
                                    let returnTime = parseInt(inbound_duration) / 60 / 60;
                                    let returnSec = returnTime - Math.floor(returnTime);
                                    returnSec = Math.round(returnSec * 60);
                                    returnTime = `${Math.floor(returnTime)} שעות ו -${returnSec} דקות`;

                                    let outbound_from_iata = flight?.raw_flight?.legs[0].from_iata;
                                    let outbound_to_iata = flight?.raw_flight?.legs[0].to_iata;
                                    let inbound_from_iata = flight?.raw_flight?.legs[1].from_iata;
                                    let inbound_to_iata = flight?.raw_flight?.legs[1].to_iata;


                                    let priceChangeCaption = priceDeltaPP>0 ? 
                                        `(תוספת $${priceDeltaPP} לנוסע)`:
                                        `(חסכון $${priceDeltaPP*-1} לנוסע)`;
                                    if (priceDeltaPP == 0)
                                        priceChangeCaption = 'ללא שינוי במחיר החופשה'

                                    const isOriginalFlight = originalFlightInventoryUID == flight?.raw_flight?.inventory_uid;
                                    if (isOriginalFlight)
                                        priceChangeCaption = 'הטיסה הכלולה בחופשה זו';
                                
                                    let outbound_airline_code = flight?.raw_flight?.legs[0].airline_code;
                                    let inbound_airline_code = flight?.raw_flight?.legs[1].airline_code;
                                    let outbound_airline_name = flight?.raw_flight?.legs[0].airline_name;
                                    let inbound_airline_name = flight?.raw_flight?.legs[1].airline_name;

                                    
                                    /*let outbound_from_iata = flight?.raw_flight?.legs[0].from_iata;
                                    let outbound_to_iata = flight?.raw_flight?.legs[0].to_iata;
                                    let inbound_from_iata = flight?.raw_flight?.legs[1].from_iata;
                                    let inbound_to_iata = flight?.raw_flight?.legs[1].to_iata;*/
                                    
                                    
                                    return  (
                                    <>
                                        {index==0?(<h3 style={{fontWeight:700, paddingBottom:0, textAlign:'right'}}>הטיסה הכלולה בחופשה</h3>):index==1?(<h3 style={{fontWeight:700, paddingBottom:0, textAlign:'right'}}>טיסות נוספות</h3>):''}
                                        <div className="card" key={'item'+index} style={isOriginalFlight?{boxShadow:'3px 3px 3px rgba(0, 0, 0, 0.1)', border:'2px solid #666',borderRadius:'10px',marginBottom:'1em',padding:'0.5em',backgroundColor:'#fff'}:{border:'1px solid #ccc',borderRadius:'10px',marginBottom:'1em',padding:'0.5em',backgroundColor:'#fff', boxShadow:'3px 3px 3px rgba(0, 0, 0, 0.1)'}} onClick={index==0?handleClose:null}>
                                            
                                            <div style={{textAlign:'right', color:'#333', direction:'rtl', padding: '0.8em', width:'100%'}} title="טיסות ישירות">
                                                {/*<h3 style={{fontSize:'1.4em', fontWeight:800, margin:'0 0 0.3em'}}>טיסות  
                                                    <span style={{margin:'0 0.25em',fontSize:'0.6em', color:'#777'}}>(ישירות)</span>
                                                </h3>*/}
                                
                                                <div className="route"  style={{fontSize:'1em', alignItems:'center',textAlign:'center',padding:'0 0 1.2em'}}  title={`טיסה ישירה בהלוך מ-${outbound_departure_airport_name} ${outbound_departure_date} ${outbound_departure_time} (${outbound_duration}) אל ${outbound_arrival_airport_name} ${outbound_arrival_date} ${outbound_arrival_time}`}> 
                                                    <div style={{marginLeft:'1.5em'}}>
                                                        <div className="airline"><img style={{maxWidth:'32px', maxHeight:'32px', border:'1px solid #bbb', borderRadius:'5px'}} src={outbound_airline_code!=null?'/images/airlines/32/'+outbound_airline_code+'.png':''} alt={outbound_airline_code} className={"airline "+(outbound_airline_code==null?'hide':'')} /></div>
                                                        <p style={{fontWeight:600, fontSize:'.8em'}}>{outbound_airline_name}</p>
                                                    </div>
                                                    <div className="from" style={{width:'25%', textAlign:'right'}}>
                                                        <div>
                                                            <p style={{fontSize:'1em', color:'#333', fontWeight:600}}>{outbound_from_iata} </p>
                                                            <div className="time" style={{fontSize:'1.2em',fontWeight:'700',lineHeight:1}}>{outbound_departure_time}</div>
                                                            <p style={{fontSize:'0.8em', color:'#555'}}>{outbound_departure_date}</p>
                                                        </div>
                                                        {/*<p>יום {outbound_departure_dow}` {outbound_departure_time}</p>*/}
                                                    </div>
                                
                                                    <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', minWidth: '30%'}}>
                                                        <div style={{width:'100%'}}>
                                                            <div className="duration" style={{width: 'inherit'}}><p className="duration_caption">{goTime}</p>
                                                            <p className="duration_comment" style={{fontSize:'0.9em'}}>טיסה ישירה</p></div>
                                                        </div>
                                                        <div style={{fontSize: '0.75em', marginRight: '2px', color: '#666'}}><FontAwesomeIcon icon={faPlane} style={{transform: 'rotate(180deg)'}} /></div>
                                                    </div>
                                                    
                                                    
                                                    <div className="to" style={{ minWidth: '25%', textAlign:'left'}}>
                                                        <p style={{fontSize:'1em', color:'#333', fontWeight:600}}>{outbound_to_iata} </p>
                                                        <div className="time" style={{fontSize:'1.2em',fontWeight:'700',lineHeight:1}}>{outbound_arrival_time}</div>
                                                        <p style={{fontSize:'0.8em', color:'#555'}}>{outbound_arrival_date}</p>
                                                    </div>
                                                </div>
                                                <div><hr /></div>
                                                <div className="route"  style={{padding:'1.2em 0 0',fontSize:'1em', alignItems:'center',textAlign:'center'}}  title={`טיסה ישירה בחזור מ-${inbound_departure_airport_name} ${inbound_departure_date} ${inbound_departure_time} (${inbound_duration}) אל ${inbound_arrival_airport_name} ${inbound_arrival_date} ${inbound_arrival_time}`}> 
                                                    <div style={{marginLeft:'1.5em'}}>
                                                        <div className="airline"><img  style={{maxWidth:'32px', maxHeight:'32px', border:'1px solid #bbb', borderRadius:'5px'}}  src={inbound_airline_code!=null?'/images/airlines/32/'+inbound_airline_code+'.png':''} alt={inbound_airline_code} className={"airline "+(inbound_airline_code==null?'hide':'')} /></div>
                                                        <p style={{fontWeight:600, fontSize:'.8em'}}>{inbound_airline_name}</p>
                                                    </div>
                                                    <div className="from" style={{width:'25%', textAlign:'right'}}>
                                                        <p style={{fontSize:'1em', color:'#333', fontWeight:600}}>{inbound_from_iata} </p>
                                                        <div className="time" style={{fontSize:'1.2em',fontWeight:'700',lineHeight:1}}>{inbound_departure_time}</div>
                                                        <p style={{fontSize:'0.8em', color:'#555'}}>{inbound_departure_date}</p>
                                                        {/*<p>יום {inbound_departure_dow}` {inbound_departure_time}</p>*/}
                                                    </div>
                                                    
                                                    <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', minWidth: '30%'}}>
                                                        <div style={{width:'100%'}}>
                                                            <div className="duration" style={{width: 'inherit'}}><p className="duration_caption">{returnTime}</p>
                                                            <p className="duration_comment" style={{fontSize:'0.9em'}}>טיסה ישירה</p></div>
                                                        </div>
                                                        <div style={{fontSize: '0.75em', marginRight: '2px', color: '#666'}}><FontAwesomeIcon icon={faPlane} style={{transform: 'rotate(180deg)'}} /></div>
                                                    </div>
                                
                                                    <div className="to" style={{ minWidth: '25%', textAlign:'left'}}>
                                                        <p style={{fontSize:'1em', color:'#333', fontWeight:600}}>{inbound_to_iata} </p>
                                                        <div className="time" style={{fontSize:'1.2em',fontWeight:'700',lineHeight:1}}>{inbound_arrival_time}</div>
                                                        <p style={{fontSize:'0.8em', color:'#555'}}>{inbound_arrival_date}</p>
                                                    </div>
                                                </div>
                                
                                            </div> 

                                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'.5em'}}>
                                                <div style={priceDeltaPP<=0?
                                                    {padding:'0 .5em', fontSize:'.9em',fontWeight:600, color:'#48811e', textAlign:'right'}:
                                                    {padding:'0 .5em', fontSize:'.9em',fontWeight:600, color:'#333',textAlign:'right'}}>
                                                        <h3 style={{display:'inline-block', padding:'0 0 0 .25em', fontWeight:800, fontSize:'1.6em', verticalAlign:'middle'}}>{isOriginalFlight?
                                                                                                                                                        '':
                                                                                                                                                        priceDeltaPP==0?'':
                                                                                                                                                            priceDeltaPP>0?'$'+priceDeltaPP+'+':'$'+Math.abs(priceDeltaPP)+'-'}</h3>
                                                        <span style={{color:'#555', fontSize:'1.1em'}}>{priceChangeCaption}</span></div>
                                                
                                                <div style={(isOriginalFlight)?
                                                    {display:'none'}:
                                                    {display:'block'}}>
                                                    <button style={{backgroundColor:'#1e5881', color:'#fff', borderRadius:'5px', border:'none', padding:'.35em .6em', fontWeight:600}} onClick={e=>changeFlight(e, flightInventoryUID)}>החלפת טיסה</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )})}
                            </Grid>    
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                {flightsArray.length <= 1 ? (<div style={{textAlign:'center', padding:'0em 3em'}}><h3 align="center" style={{color:'#555', fontWeight:700}}>
                                    לא נמצאו טיסות נוספות ליעד זה בתאריך המבוקש</h3></div>) : ''}
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
    
            </Dialog>
    );
}
