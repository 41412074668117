import React from "react";
//import configData from '../../../config/config.json';
import { Tune as FilterIcon} from "@material-ui/icons";
import { PeopleAlt as PeopleIcon } from "@material-ui/icons";
import { Close as CloseIcon } from "@material-ui/icons";
import { ChevronRight as ArrowDown } from "@material-ui/icons";
import { KeyboardArrowLeft as ArrowLeft } from "@material-ui/icons";
import { Grid, Button } from "@material-ui/core";
import Calendar from '../../Calendar'
import Destinations from '../../Destinations'
import './index.css';
import { ReactComponent as CalendarIcon} from '../../../assets/images/icons/calendar.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';


const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
    },
}))(MuiDialogContent);
  
const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: 0//theme.spacing(1),
    },
}))(MuiDialogActions);

const DialogTitle = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
}))(MuiDialogTitle);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


class GlobalFilters extends React.Component {

    constructor (props) {
        super(props)

        this.state = {
            'filters' : {
                ...this.props?.filters
            },
            drawer: {
                open: false
            }
        }


        // Binding
        this.handleToggleFilters = this.handleToggleFilters.bind(this);
        this.handleShowResults = this.handleShowResults.bind(this);
        
        this.closeDates = this.closeDates.bind(this);
        this.toggleCalendar = this.toggleCalendar.bind(this); 
        
    }

    
    toggleCalendar = (e) => {
        e.stopPropagation();

        this.props.ResetDates()
        
        console.log('toggle picker global')
        /*this.setState(prevState => (
            {
                ...prevState, 
                calendar:!prevState?.calendar,
                
            }
        )
        )*/

        this.props.toggleCalendar(e);
    }
    
    
    handleCalendarChange = (dates, datesType) => {
        this.props.handleCalendarChange(dates, datesType)
       
        const from = (dates[0])
        const to = (dates[1])

        if (!from && !to) return

        const sqlFromDate = !dates[0]?null:(from.getFullYear()) + '-' + (from.getMonth()*1+1) + '-' + from.getDate();
        const sqlToDate = !dates[1]?null:(to.getFullYear()) + '-' + (to.getMonth()*1+1) + '-' + to.getDate();

        this.setState(prevState=>({
            ...prevState,
            filters: {
                ...prevState.filters,
                from_date: sqlFromDate,
                from: from,
                to_date: sqlToDate,//toDate?toDate.getFullYear() + "-" + ((toDate.getMonth()*1)+1) + '-' + toDate.getDate():null,
                to: to,
                when_caption:  !to?
                    from.getDate() + "/" + (from.getMonth()*1+1) + '/' + (from.getFullYear()+'').replace('20',''):
                    from.getDate() + "/" + (from.getMonth()*1+1) + '/' + (from.getFullYear()+'').replace('20','') + " עד " + (to.getDate() + "/" + (to.getMonth()*1+1)) + '/' + ((to.getFullYear()+'').replace('20',''))
                ,
                dates_type: datesType,
                when: datesType,
                months: [],
                holidays: [],
                months: [], // reset months,
                dow: [], // reset dow selection
                nights: [], // reset nights,
            },
            calendar: !to // show date picker until target date selected

        }))
    }

    handleCloseCalendar = (params) => {
        this.setState(prevState => (
            {
                ...prevState, 
                calendar:false,
                
            }
        ))
    }

    

    handleToggleFilters = (e) => {
        e.preventDefault();
        
        // Create a copy of current state
        if (!this.state?.drawer?.open)
            this.filters_state = JSON.parse(JSON.stringify(this.state));

        this.setState(prevState=>{
            return {
                ...prevState,
                drawer: {
                    open: !this.state?.drawer?.open
                }
            }
        })
    }

    handleToggleSearchPax = (e) => {
        e.preventDefault();

        // Create a copy of current state
        if (!this.state?.drawer?.open)
            this.filters_state = JSON.parse(JSON.stringify(this.state));

        this.setState(prevState=>{
            return {
                ...prevState,
                drawer: {
                    open: !this.state?.drawer?.open
                }
            }
        })  
    }

    closeFiltersWindow = (e) => {
        // Restore previous state (and remove new filters) - since we didn't submit the changes
        this.setState(this.filters_state);
    }
    
    handleShowResults = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.handleToggleFilters(e);
        this.props.handleSubmit(e)
        
    }
    
    closeDates = () => {
        
        this.setState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    from_date: '',
                    to_date: '',
                    dates_type: '',
                }
            })
        )
    }

    ResetDow = () => {

        console.log('reset dow')
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                dow: []
            }
        }));
   }


    closeDestination = () => {
        this.setState(prevState=>({
            ...prevState,
            destinationsWindow: false,
            filters: {
                ...prevState.filters,
                page: '',
                no_more_deals: false
            }
        }))
    }

    closeDate = () => {
        this.setState(prevState=>({
            ...prevState,
            filters: {
                ...prevState.filters,
                month: "",
                from_date: "",
                to_date: "",
                page: '',
                no_more_deals: false
            }
        })/*,()=>
            this.props.sendDataToCaller(this.state.filters, true)
        */)
    }

    render(){
    
        let when_caption = 'בכל התאריכים';
        let dates_caption = when_caption;

        if (this.props.filters.months && this.props.filters.months?.length>0) {
            if (this.props.filters.months.length>1) {
                when_caption = 'בחודשים הנבחרים'
            } else {
                when_caption = 'בחודש הנבחר'
            }
        }

        if (this.props.filters.from_date && this.props.filters.to_date) {
            when_caption = 'בתאריכים הנבחרים'
            
            const from = this.props.filters.from_date;
            const to = this.props.filters.to_date;

            // fixed according to this suggestions: https://stackoverflow.com/questions/61218243/javascript-returns-nan-only-in-ios-browsers
            const fromDate = new Date(from.split('-')[0],from.split('-')[1]-1,from.split('-')[2]);
            const toDate = new Date(to.split('-')[0],to.split('-')[1]-1,to.split('-')[2]);

          // todo: fix iphone bug showing this as NaN/NaN/NaN...
            when_caption =  (fromDate.getDate() + "/" + (fromDate.getMonth()*1+1) + '/' + (toDate.getFullYear()+'').replace('20','') + " עד " + (toDate.getDate() + "/" + (toDate.getMonth()*1+1)) + '/' + (toDate.getFullYear()+'').replace('20',''))
            dates_caption = when_caption
        }

        if (this.props.filters.holidays && this.props.filters.holidays.length > 0) {
            when_caption = 'בחגים הנבחרים'
        }

        return (
            <>
                <div className="GlobalFilters mobile wrapper" id="FiltersWrapper">

                    <div className="scrollable">
                        <Button
                            className="ActiveFilterButton Pax"
                            variant='outlined'
                            color='secondary'
                            title={`סינון תוצאות לפי ${this.props?.filters?.adults} מבוגרים ${this.props?.filters?.teens} נערים ${this.props?.filters?.children} ילדים ${this.props?.filters?.infants} תינוקות 
                            (לחצו לשינוי)`}
                            onClick={e=>{this.props.gotoStep(e, 0, true)}}
                            startIcon={<PeopleIcon className='BlueIcon' />}
                            endIcon={<ArrowLeft className='Rotate270deg' />}
                            >{
                                this.props?.filters?.teens + this.props?.filters?.children + this.props?.filters?.infants == 0 ?
                                        `${this.props?.filters?.adults} מבוגרים`:
                                        this.props?.filters?.adults==2 && this.props?.filters?.children + this.props?.filters?.infants > 0 && this.props?.filters?.teens == 0?
                                            `זוג+${this.props?.filters?.children*1 + this.props?.filters?.infants*1}`:
                                            `${this.props?.filters?.adults*1 + this.props?.filters?.teens*1 + this.props?.filters?.children*1 + this.props?.filters?.infants*1} נוסעים`
                            }
                        </Button>
                        <Button
                            className="ActiveFilterButton Pax"
                            title={`סינון תוצאות לפי ${this.props?.filters?.nights && this.props?.filters?.nights.length>0?
                                Math.min(...this.props?.filters?.nights)
                                :3
                            } לילות (לחצו לשינוי)`}
                            variant='outlined'
                            color='secondary'
                            onClick={e=>{this.handleToggleFilters(e)}}
                            endIcon={<ArrowLeft className='Rotate270deg' />}
                            >{this.props?.filters?.nights && this.props?.filters?.nights.length>0?
                                Math.min(...this.props?.filters?.nights)
                                :3
                            }{this.props?.filters?.nights.length>0 && Math.min(...this.props?.filters?.nights)!='7'?'+':''} לילות
                        </Button>
                        <Button
                            title={`סינון תוצאות לפי ${this.props?.filters?.stars.length>0?
                                Math.min(...this.props?.filters?.stars) :
                                '3'
                              
                            } כוכבים (לחצו לשינוי)`}
                            className="ActiveFilterButton Pax"
                            variant='outlined'
                            color='secondary'
                            onClick={e=>{this.handleToggleFilters(e)}}
                            endIcon={<ArrowLeft className='Rotate270deg' />}
                            >{this.props?.filters?.stars.length>0?
                                Math.min(...this.props?.filters?.stars) :
                                '3'
                              }{this.props?.filters?.stars.length>0 && Math.min(...this.props?.filters?.stars)!='5'?'+':''} כוכבים
                        </Button>
                        <Button
                            title={`סינון תוצאות לפי ${this.props?.filters?.meals.find(meal=>meal=='BB')?
                                'ארוחת בוקר':
                                (
                                    this.props?.filters?.meals.find(meal=>meal=='AI')?
                                        'הכל כלול':
                                        'לינה בלבד'
                                )
                            }  (לחצו לשינוי)`}
                            className="ActiveFilterButton Pax"
                            variant='outlined'
                            color='secondary'
                            onClick={e=>{this.handleToggleFilters(e)}}
                            endIcon={<ArrowLeft className='Rotate270deg' />}
                            >{this.props?.filters?.meals.find(meal=>meal=='BB')?
                                'ארוחת בוקר':
                                (
                                    this.props?.filters?.meals.find(meal=>meal=='AI')?
                                        'הכל כלול':
                                        'לינה בלבד'
                                )
                            }
                        </Button>
                        
                        
                        {this.props?.filters?.from_date && this.props?.filters?.from_date!==''?
                            (
                                <Button
                                    title={`בטל סינון לפי תאריכים`}
                                    className={`ActiveFilterButton`}
                                    variant='outlined'
                                    color='secondary'
                                    endIcon={<CloseIcon onClick={e=>this.props.ResetDates(true)} />}
                                    >{when_caption}
                                </Button>
                            ):''
                        }


                        {this.props?.filters?.dow?.toString()==='fri,sat'?
                            (
                                <Button
                                    title='בטל סינון לפי סופי שבוע'
                                    className={`ActiveFilterButton`}
                                    variant='outlined'
                                    color='secondary'
                                    endIcon={<CloseIcon onClick={e=>this.props.toggleDOW('fri,say', true)} />}
                                    >סופ"ש
                                </Button>
                            ):''
                        }

                        {/*this.state?.filters?.meals.map(meal_code=>{
                            return (
                            <Button
                                key={`btn_${meal_code}`}
                                className={`ActiveFilterButton`}
                                variant='outlined'
                                color='secondary'
                                startIcon={<CloseIcon onClick={e=>this.toggleMealCode(meal_code, true)} />}
                                >{meal_code==='BB'?'ארוחת בוקר':'הכל כלול'}
                            </Button>
                            )
                        })*/}

                        {/*this.state?.filters?.stars.map(star=>{
                            return (
                            <Button
                                key={`stars_${star}`}
                                className={`ActiveFilterButton`}
                                variant='outlined'
                                color='secondary'
                                startIcon={<CloseIcon onClick={e=>this.toggleStars(star, true)} />}
                                >{star} כוכבים
                            </Button>
                            )
                        })*/}

                        <Button
                            title='בטל סינון לפי חודשים'
                            className={`ActiveFilterButton ${(!this.props?.month)?'hidden':''}`}
                            variant='outlined'
                            color='secondary'
                            endIcon={<CloseIcon onClick={e=>this.closeDate()} />}
                            >{this.props?.month_name} {this.props?.year}
                        </Button>

                        <Button
                            title='בטל סינון לפי יעד'
                            className={`ActiveFilterButton ${(!this.props?.destination_name)?'hidden':''}`}
                            variant='outlined'
                            color='secondary'
                            endIcon={<CloseIcon onClick={e=>this.closeDestination()}/>}
                            >{this.props?.destination_name}
                        </Button>

                    </div>

                    <div className="static">
                        <Button
                            title='בחירת אפשרויות סינון תוצאות'
                            className="ActiveFilterButton"
                            variant='outlined'
                            color='primary'
                            onClick={e=>{this.handleToggleFilters(e)}}
                            startIcon={<FilterIcon style={{color:'#1f5981'}} />}
                            test={{'data-testid':'filters-button'}}
                            >סינון
                        </Button>
                    </div>
                    
                    
                   



                </div>


                <Dialog 
                    fullScreen 
                    open={this.state?.drawer?.open?this.state?.drawer?.open:false} 
                    onClose={e=>{this.closeFiltersWindow(e)}} 
                    className='GlobalFiltersWindow'
                    scroll="paper"
                    TransitionComponent={Transition}
                    style={{padding: 0}}
                    id="filters_window"
                    > 
                    <DialogTitle>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={0}>
                            <Grid item tabIndex={0}>
                                <Typography variant="h1" style={{flex: 1}}>
                                    סינון מתקדם
                                </Typography>
                            </Grid>
                            <Grid item >
                                <IconButton edge="start" color="inherit" onClick={e=>{this.closeFiltersWindow(e)}} aria-label="close" 
                                    style={{margin:0, paddingTop: 0}}>
                                        <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="FiltersContent">
                            <Grid item>
                                <label tabIndex={0}>
                                <Grid container>
                                    <Grid item>
                                        <h2> רמת המלון - כוכבים (ניתן לסמן יותר מאפשרות אחת)</h2></Grid>
                                    </Grid>
                                <Grid container>
                                    <Grid>
                                        <Button 
                                            title='אין לי העדפה של רמת המלון'
                                            aria-label='אין לי העדפה של רמת המלון'
                                            onClick={e=>this.props.toggleStars('all')}
                                            variant={(this.props?.filters?.stars && this.props.filters.stars?.length==0?'contained':'outlined')}>
                                                אין לי העדפה</Button>
                                        <Button 
                                            title='מלונות 3 כוכבים'
                                            aria-label='מלונות 3 כוכבים'
                                            onClick={e=>this.props.toggleStars('3')}
                                            variant={(this.props?.filters?.stars.find(star=>star==='3')?'contained':'outlined')}>3 ★</Button>
                                        <Button
                                            title='מלונות 4 כוכבים'
                                            aria-label='מלונות 4 כוכבים'
                                            onClick={e=>this.props.toggleStars('4')}
                                            variant={(this.props?.filters?.stars.find(star=>star==='4')?'contained':'outlined')}>4 ★</Button>
                                        <Button 
                                            title='מלונות 5 כוכבים'
                                            aria-label='מלונות 5 כוכבים'
                                            onClick={e=>this.props.toggleStars('5')}
                                            variant={(this.props?.filters?.stars.find(star=>star==='5')?'contained':'outlined')}>5 ★</Button>
                                    </Grid>
                                </Grid>
                                </label>


                                <label tabIndex={0}>
                                <Grid container>
                                    <Grid item>
                                        <h2>בסיס אירוח</h2>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid>
                                    <Button 
                                        title='אין לי העדפה של בסיס האירוח'
                                        onClick={e=>this.props.toggleMealCode('all')}
                                        variant={(this.props?.filters?.meals && this.props?.filters?.meals.length===0?'contained':'outlined')}>אין לי העדפה</Button>
                                    <Button 
                                        title='תוצאות הכוללות ארוחת בוקר'
                                        onClick={e=>this.props.toggleMealCode('BB')}
                                        variant={(this.props?.filters?.meals.find(meal=>meal==='BB')?'contained':'outlined')}>ארוחת בוקר</Button>
                                    <Button 
                                        title='תוצאות הכוללות אירוח הכל כלול'
                                        onClick={e=>this.props.toggleMealCode('AI')}
                                        variant={(this.props?.filters?.meals.find(meal=>meal==='AI')?'contained':'outlined')}>הכל כלול</Button>
                                    </Grid>
                                </Grid>
                                </label>


                                <label tabIndex={0}>
                                <Grid container>
                                    <Grid item>
                                        <h2>תקופה מועדפת</h2>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid>
                                        <Button 
                                            title='אין לי העדפה של תקופה'
                                            onClick={e=>this.props.toggleDOW('all')}
                                            variant={this.props?.filters?.dow && this.props?.filters?.dow.length==0 && !this.props.filters?.from_date?'contained':'outlined'}>אין לי העדפה</Button>
                                        <Button 
                                            title='סינון תוצאות בסופי שבוע בלבד'
                                            onClick={e=>this.props.toggleDOW('fri,sat')}
                                            variant={this.props.filters?.dow.length>0 && ['fri','sat'].every(i=>this.props.filters.dow.includes(i))?'contained':'outlined'}>סופי שבוע</Button>
                                        {
                                            this.props.filters?.dow.length>0 && ['sun','mon','tue','wed','thu'].every(i=>this.props.filters.dow.includes(i))?
                                            (
                                                <Button 
                                                    onClick={e=>this.props.toggleDOW('all')}
                                                    variant={this.props.filters?.dow.length>0 && ['sun','mon','tue','wed','thu'].every(i=>this.props.filters.dow.includes(i))?'contained':'outlined'}>אמצע השבוע</Button>
                                            ):(<></>)
                                        }

                                        {/*<Button 
                                            onClick={e=>this.toggleMonth('fri,sat')}
                                            variant={!this.props?.filters?.months || this.props?.filters?.months===''?'contained':'outlined'}>בחר חודשים</Button>*/}
                                        <Button
                                            title='תוצאות בתאריכים ספציפיים'
                                            style={{direction:'rtl'}}
                                            className="searchToggleButton dates calendar"
                                            variant={this.props.filters?.from_date && this.props.filters?.holidays.length==0 && this.props.filters?.dow.length==0?'contained':'outlined'}
                                            color='primary'
                                            onClick={e=>this.toggleCalendar(e)} 
                                            startIcon={<CalendarIcon />}
                                            >{this.props.filters?.from_date && this.props.filters?.holidays.length==0 && this.props.filters?.dow.length==0?dates_caption:'בחר תאריכים '}
                                        </Button>
                                    </Grid>
                                </Grid>
                                </label>

                                <label tabIndex={0}>
                                <Grid container>
                                    <Grid item>
                                        <h2>מאפייני המלון</h2>
                                    </Grid>
                                </Grid>


                                <Grid container>
                                    <Grid item className="NarrowButton" >
                                        <Button 
                                            title='אין לי העדפה מיוחדת '
                                            onClick={e=>this.props.toggleResetSpecialHotel()}
                                            variant={!this.props?.filters?.highlights || this.props?.filters?.highlights=={} || (!this.props?.filters?.highlights?.waterpark && !this.props?.filters?.highlights?.casino && !this.props?.filters?.highlights?.luxury && !this.props?.filters?.highlights?.near_chabad && !this.props?.filters?.highlights?.spa) ?'contained':'outlined'}>אין לי העדפה</Button>
                                        <Button 
                                            title='הצג מלונות עם פארק מים'
                                            onClick={e=>this.props.toggleWaterpark()}
                                            variant={this.props?.filters?.highlights?.waterpark?'contained':'outlined'}>פארק מים</Button>
                                        <Button 
                                            title='הצג מלונות עם קזינו'
                                            onClick={e=>this.props.toggleCasino()}
                                            variant={this.props?.filters?.highlights?.casino?'contained':'outlined'}>קזינו</Button>
                                        <Button 
                                            title='הצג מלונות יוקרה'
                                            onClick={e=>this.props.toggleLuxury()}
                                            variant={this.props?.filters?.highlights?.luxury?'contained':'outlined'}>מלון יוקרה</Button>
                                        <Button 
                                            title='הצג מלונות קרובים לבית חבד'
                                            onClick={e=>this.props.toggleNearChabad()}
                                            variant={this.props?.filters?.highlights?.near_chabad?'contained':'outlined'}>
                                                קרוב לבית חב"ד</Button>
                                        <Button 
                                            title='הצג מלונות עם ספא'
                                            onClick={e=>this.props.toggleSpa()}
                                            variant={this.props?.filters?.highlights?.spa?'contained':'outlined'}>
                                                ספא במלון</Button>
                                        {/*<Button 
                                            disabled
                                            onClick={e=>this.props.toggleNearChabad()}
                                            variant={this.props?.filters?.near_chabad?'contained':'outlined'}>
                                        <span style={{color:'#bbb'}}>באתר סקי (בקרוב)</span></Button>*/}
                                    </Grid>
                                </Grid>
                                </label>
                            

                                <label tabIndex={0}>
                                <Grid container>
                                    <Grid item>
                                        <h2>כמות לילות</h2>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item className="NarrowButton" >
                                        <Button 
                                            title='אין לי העדפה לגבי כמות הלילות במלון'
                                            onClick={e=>this.props.toggleNights('all')}
                                            variant={!this.props?.filters?.nights || this.props?.filters?.nights.length==0?'contained':'outlined'}>אין לי העדפה</Button>
                                        <Button 
                                            title='הצג דילים עם 3 לילות ומעלה'
                                            onClick={e=>this.props.toggleNights('3')}
                                            variant={(this.props?.filters?.nights.find(night=>night==='3')?'contained':'outlined')}>3</Button>
                                        <Button 
                                            title='הצג דילים עם 4 לילות ומעלה'
                                            onClick={e=>this.props.toggleNights('4')}
                                            variant={(this.props?.filters?.nights.find(night=>night==='4')?'contained':'outlined')}>4</Button>
                                        <Button 
                                            title='הצג דילים עם 5 לילות ומעלה'
                                            onClick={e=>this.props.toggleNights('5')}
                                            variant={(this.props?.filters?.nights.find(night=>night==='5')?'contained':'outlined')}>5</Button>
                                        <Button 
                                            title='הצג דילים עם 6 לילות ומעלה'
                                            onClick={e=>this.props.toggleNights('6')}
                                            variant={(this.props?.filters?.nights.find(night=>night==='6')?'contained':'outlined')}>6</Button>
                                        <Button 
                                            title='הצג דילים עם 7 לילות ומעלה'
                                            onClick={e=>this.props.toggleNights('7')}
                                            variant={(this.props?.filters?.nights.find(night=>night==='7')?'contained':'outlined')}>7</Button>
                                    </Grid>
                                </Grid>
                                </label>



                                <label tabIndex={0}>
                                <Grid container>
                                    <Grid item>
                                        <h2>חברות תעופה</h2>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item className="NarrowButton" >
                                        <Button 
                                            title='אין לי העדפה של חברות תעופה'
                                            onClick={e=>this.props.toggleIsraeliAirlines('all')}
                                            variant={!this.props?.filters?.airlines || this.props?.filters?.airlines.length==0?'contained':'outlined'}>אין לי העדפה (מומלץ)</Button>
                                        <Button 
                                            title='הצג דילים עם חברות ישראליות בלבד'
                                            onClick={e=>this.props.toggleIsraeliAirlines('LY,IZ,6H')}
                                            variant={this.props?.filters?.airlines=='LY,IZ,6H'?'contained':'outlined'}>חברות ישראליות בלבד</Button>
                                    </Grid>
                                </Grid>
                                </label>



                                <label tabIndex={0}>
                                <Grid container>
                                    <Grid item>
                                        <h2>היעדים שיוצגו בתוצאות</h2>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid>
                                    <Button 
                                        title='הצג דילים בכל היעדים'
                                        onClick={e=>this.props.resetDestinations(e)}
                                        variant={(!this.props?.filters?.destinations || this.props?.filters?.destinations.length==0?'contained':'outlined')}>כל היעדים (מהזול ליקר)</Button>
                                    <Button 
                                        title='הצג דילים ביעדים נבחרים בלבד'
                                        onClick={e=>this.props.toggleDestinations(e)}
                                        variant={this.props?.filters?.destinations && this.props?.filters?.destinations.length >0?'contained':'outlined'}>
                                            {
                                                this.props?.filters?.destinations && this.props?.filters?.destinations.length > 0?
                                                        `הצג  ${this.props?.filters?.destinations.length+''} יעדים שבחרתי ` 
                                                    :
                                                        'בחירת יעדים'
                                            }
                                    </Button>
                                    </Grid>
                                </Grid>
                                </label>


                                
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container>
                            <Grid item xs={12} className="Confirm"  onClick={e=>{this.handleShowResults(e)}} >
                                <Button tabIndex={0}>הצג תוצאות <ArrowLeft /></Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>

                    
                    
                <Calendar 
                    open={this.state?.calendar?true:false} 
                    sendCalendarData={this.handleCalendarChange} 
                    onCloseCalendar={this.handleCloseCalendar}
                    range={this.props.filters?.dates} 
                    dates_type={this.props.filters?.dates_type} 
                    filters={this.props.filters}>
                </Calendar>

                <Destinations
                    open={this.props?.filters.showDestinationsWindow?true:false} 
                    onConfirmDestinationsSelection={this.props.handleDestinationsChange} 
                    onCloseDestinations={this.props.handleCloseDestinationsWindow}
                    resetDestinations={this.props.resetDestinations}
                    destinations={this.props?.destinations}
                    filters={this.props.filters}>
                </Destinations>
            </>
            
        )
    }

        
} export default GlobalFilters