import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
//import { blue } from '@material-ui/core/colors';

import { Grid, Button } from "@material-ui/core";

/*
const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});
*/


export default function SimpleDialog(props) {
    const { onOk, openPackageNotFound} = props;

    const handleOkGoToHomepage = () => {
        onOk();
    };

    // onClose={handleClose} 
    return (
        <Dialog maxWidth='xs' fullWidth={true} aria-labelledby="simple-dialog-title" open={openPackageNotFound} keepMounted>
            <div className='ugly modalBody p-4'>

                <Grid container id="PackageNotFoundModal" className="UserNotificationModal">
                    <Grid item className='text-center' xs={12}>
                        <div className="heading">
                            <img alt="איזה באסה, הדיל  אזל :(" src="/images/modals/package-not-found-heading.png" style={{width:'200px'}} /><h3>איזה באסה, הדיל  אזל :(</h3>
                        </div>
                    </Grid>
                    <Grid item className='mb-4 text-center heading' xs={12} >
                        <div className="content">
                            {/*<h3>אבל אל תדאגו...<br /><br />אנחנו מחזירים אתכם לדף הבית<br />שם תמצאו דילים לא פחות טובים!</h3>*/}
                            <h3>אך אל דאגה! יש לנו  עוד דילים מעולים באותם תאריכים...</h3>
                        </div>
                    </Grid>
                    <Grid item  className='text-center' xs={12}><Button onClick={handleOkGoToHomepage} variant='contained' color='secondary' title="הצג דילים נוספים">תראו לי</Button></Grid>
                </Grid>
            </div>

        </Dialog>
    );
}
