import React from 'react'
import axios from 'axios';

/**
 I'll provide a link with short url in one of the following forms:
 https://www.afnu.co.il/s/short-url-code
 http://www.afnu.co.il/s/short-url-code
 http://afnu.co.il/s/short-url-code
 http://afnu.co.il/s/short-url-code
 
 you'll read the existing page URL and  strip everything but the short-url-code
 then you'll call the API with the short-url-code and get the long URL in the following format: https://api.afnu.co.il/url-shortner/[short-url-code]
 then you'll redirect to the long URL
 */

class URLShortner extends React.Component {

    constructor(props) {
        super(props);
        console.log(props.location.pathname)
        const shortCode = props.location.pathname.split('/')[2];

        this.getLongURL(shortCode);
        /*
        this.state = {
            shortCode: shortCode,
            longURL: '',
            isLoaded: false
        }*/


    }

    componentDidMount() {
        //this.getLongURL(this.state.shortCode);
        
    }


    getLongURL(shortCode) {
        //const longURL = 'https://api.afnu.co.il/url-shortner/' + shortCode;

        try {
            axios.get(`https://api.afnu.co.il/url-shortner/${shortCode}`)
                .then(res => this.setState({
                    longURL: res.data,
                    isLoaded: true
                },()=>{
                    console.log("longURL: ", this.state.longURL);


                    if (this.state.longURL && this.state.longURL.indexOf('www.afnu.co.il') > 0) {
                        window.location.href = this.state.longURL;
                    }
            
                }))
                .catch(err => {
                    console.log(err)
                    //throw new Error(err)
                    window.location.href = '/'
                }
            )    
        } catch(e)     {
            window.location.href = '/'
        }
    }

    render() {return (<></>)}
    /*render() {
        return (
            <div>
                <p>{this.state.longURL}</p>
            </div>
        )
    }*/       

}
export default URLShortner;