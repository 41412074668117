import React from 'react'
import { useHistory } from "react-router-dom";
import  ArrowForwardIos  from "@material-ui/icons/ArrowForwardIos";

function GoTo(e, history, targetURL) {
    e.preventDefault()
    
    if (history && history.length > 2) {
        history.goBack();
        return;
    }

    if (targetURL)
        window.location = targetURL;
    else
        window.location = '/';
    

    return;
    if (!targetURL) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'NavBarBackButton',
            targetUrl: targetURL,
            pageUrl: window.url,
            pageTitle: window.title
        });
        history.goBack();
    }
    else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'NavBarBackButton',
            targetUrl: targetURL,
            pageUrl: window.url,
            pageTitle: window.title
        });
        window.location = targetURL;
    }
}

const Navbar = (props) => {
    // console.log(useHistory)
    const history = useHistory()
    // console.log(props.history)
    return (

        <nav style={{ boxShadow: '4px 6px 9px 4px #888888 !important' }} className="ugly navbar navbar-light bg-white mb-1">
            <a  href="https://wa.me/972507801801" style={{position:'fixed',zIndex:'100',display:'none'}}>
                <img src="/images/whatsapp_feedback_button.png" id="chat_icon" alt="תנו לנו פידבק" style={{width:'35px',top:'390px',right:'0'}} /></a>
            <div className="container-fluid">

                <b className="navbar-brand d-flex" style={{marginRight: '-10px'}}>
                    <a href="#" style={{textDecoration:'none', color:'#000', fontSize: '.9em'}} onClick={e => GoTo(e, history, props?.previous)} title="חזרה לכל הדילים">
                      <ArrowForwardIos  
                        style={{ fontSize: "18px", color: "#555555" }}
                      />
                    
                    {" "}{props?.name}
                    </a>
                </b>
                {/* 
                <a className="navbar-brand" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                </a>
                */}
            </div>
        </nav>

    )
}

export default Navbar
