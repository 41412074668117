import React from "react"
import Intro from "../../components/Homepage/Intro"
import MainNavigationBar from "./MainNavigationBar"
import { Link } from 'react-router-dom'
//import Modal from 'react-responsive-modal';
import { Event } from "../Tracking";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faStarOfDavid, faStopwatch} from "@fortawesome/free-solid-svg-icons";


//import { faSun } from "@fortawesome/free-solid-svg-icons";

//const logo_image = require('../../images/logo.png'); // with require



class Header extends React.Component {
   
    
    constructor (props) {
        super(props)
        
        
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    //nav = React.createRef();
  
    handleScroll = () => {
        
        if (this.props.hide) return

        const lastScrollY = window.scrollY;
        if (lastScrollY > 1) {
            document.getElementById("headerNav").classList.add("fixed");
        }
        else {
            document.getElementById("headerNav").classList.remove("fixed");
        }

        if (lastScrollY > 650) {
            document.getElementById("FiltersWrapper").classList.add("fixed");
        }
        else {
            document.getElementById("FiltersWrapper").classList.remove("fixed");
        }
    };

    

    onOpenModal = () => {
        Event("BANNER", "Button clicked", window.location.pathname);
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
      };


    render() {
        //const { open } = this.state;
         //let hideHomepageIntro = this.props?.location?.search && this.props?.location?.search!=null;
      let hideHomepageIntro = (
        !new URLSearchParams(this.props?.location?.search).get("intro") && (
        new URLSearchParams(this.props?.location?.search).get("min_nights") ||
        new URLSearchParams(this.props?.location?.search).get("max_nights") ||
        new URLSearchParams(this.props?.location?.search).get("nights") ||
        new URLSearchParams(this.props?.location?.search).get("stars") ||
        new URLSearchParams(this.props?.location?.search).get("min_stars") || 
        new URLSearchParams(this.props?.location?.search).get("max_stars") || 
        new URLSearchParams(this.props?.location?.search).get("min_price") || 
        new URLSearchParams(this.props?.location?.search).get("max_price") || 
        new URLSearchParams(this.props?.location?.search).get("when") || 
        new URLSearchParams(this.props?.location?.search).get("from_date") || 
        new URLSearchParams(this.props?.location?.search).get("to_date") || 
        new URLSearchParams(this.props?.location?.search).get("dates") || 
        new URLSearchParams(this.props?.location?.search).get("months") || 
        new URLSearchParams(this.props?.location?.search).get("months_weekends") || 
        new URLSearchParams(this.props?.location?.search).get("sortby") || 
        new URLSearchParams(this.props?.location?.search).get("destination")) 
      );
      

        if (this.props.hide) {
            this.headerStatus = ' hide ';
            this.headerStyle = ' no-image noselect';
        }

        if (this.props.hide_quick_nav) {
            this.quick_nav_status = ' hide ';
            //this.headerStyle = ' fixed no-image';
        }

        return (
        <header className={this.headerStyle}  >
            <div className={'top-header ' + (this.props?.page_type=='destination'?'destination':'')}>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                
                <MainNavigationBar headerStyle={this.headerStyle} openSearchWindow={this.props.openSearchWindow} />
                    
                <Intro 
                    hidden={hideHomepageIntro} 
                    hideSearchBox={this.props?.hideSearchBox}
                    active={this.state?.active_shortcut} 
                    location={this.props?.location} 
                    filters={this.props?.filters} 
                    openSearchWindow={this.props.openSearchWindow} 
                    page_type={this.props?.page_type} />
            </div>
        </header>

        );
    }
}

export default Header